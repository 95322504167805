import { FC, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context";
import {
  WidgetBanner,
  WidgetCarouselIcon,
  WidgetSlider,
  WidgetTable,
  WidgetTaskPendingAndDone,
  // WidgetTask,
} from "../../commonComponents/Widget";
import {
  getBannerBenefits,
  getBannerNews,
  getNextBirthdays,
  getShortcuts,
  getTask,
  getVacationsAndLicenses,
} from "../services/homeServices";
import newsletterInicio from "../../../resources/images/banner-suscripcion-inicio.png";
import Box from "../../commonComponents/Box";
import CodigoDeEticaArgService from "../../../services/codigo-etica-arg/service";

const HomeAR: FC = () => {
  const { setShowNewsletter } = useContext<any>(Context);
  const navigate = useNavigate();

  const getTaskEthicalCode = async () => {
    const documentsList = await CodigoDeEticaArgService.ObtenerRecibosWithoutFunctions();
    const findDocumentWithoutSign = documentsList.find((x: any) => x?.status?.toLowerCase() === "enviado");
    const hiddenTask = !documentsList || documentsList?.length === 0;

    return hiddenTask ? null : {
      id: "ETICA-EMPLOYEE",
      country: "AR",
      module_name: "Codigo de Etica",
      component: findDocumentWithoutSign ? "/codeethics" : "",
      created_at: `Periodo: ${findDocumentWithoutSign ?  findDocumentWithoutSign.period : documentsList[0].period} `,
      parameters: "",
      title: `Codigo de Etica ${findDocumentWithoutSign ?  "pendiente de firma" : "firmado correctamente"}`,
      isDone: findDocumentWithoutSign ? false : true
    }
  };

  const getTasks = async () => {
    const ethicalCode = await getTaskEthicalCode();
    const tasks = [];
    
    ethicalCode && tasks.push(ethicalCode);
    return tasks;
  }

  return (
    <>
      <WidgetCarouselIcon
        id="accesos-directos"
        width="3/3"
        box={false}
        data={() => getShortcuts()}
      />

      <WidgetBanner
        id="noticias-destacadas"
        width="2/3"
        box={false}
        data={() => getBannerNews()}
      />

      {/* <WidgetTask
        id="tareas-pendientes"
        data={()=> getTask()}
        width="1/3"
        box={true}
      /> */}

      <WidgetTable
        id="proximos-cumpleanos"
        title="Próximos Cumpleaños"
        data={() => getNextBirthdays()}
        width="1/3"
        box={true}
      />

      <WidgetSlider
        id="beneficios-destacados"
        data={() => getBannerBenefits()}
        width="3/3"
        box={false}
      />

      <Box width="3/3" box={false}>
        <div
          className="pointer w-100"
          onClick={(e) => {
            e.preventDefault();
            setShowNewsletter(true);
            navigate("/suscripciones");
          }}
        >
          <img
            src={newsletterInicio}
            className="img-fluid p-0 w-100 rounded-4"
            alt="logo"
          />
        </div>
      </Box>

      <WidgetTable
        id="vacations-and-licenses"
        title="Mi Equipo"
        data={() => getVacationsAndLicenses()}
        width="2/3"
        box={true}
      />
      
      <WidgetTaskPendingAndDone
        id="task"
        data={() => getTasks()}
        width="1/3"
        box={true}
      />
    </>
  );
};

export default HomeAR;
