import Auth from '@aws-amplify/auth';
import axios from "axios";
import moment from 'moment';
// eslint-disable-next-line no-undef
const url = process.env.REACT_APP_BACKEND_URL_GLOBAL;

// const url = "https://localhost:5001"

const parseShiftsToEvents = (shifts) => {
    let events = [];
    shifts.forEach((shift, index) => {
        if (shift) {
            shift.id = index;

            let event = {
                id: index,
                start: new Date(shift.startDate),
                end: new Date(shift.endDate),
                priority: shift.type === 'Turno' ? 0 : null,
                type: shift.type,
                data: shift
            }
            if (shift.type !== "Franco" && shift.type !== "Turno" && shift.type !== "SinHorario") {
                Object.keys(event).forEach(key => {
                    if (key === "end" && event[key]) {
                        event["end"] = moment(event.end).add(1, 'day').toDate()
                    }
                })
            }
            if (!shift.type || shift.type === 'SinHorario') {
                event.title = 'No Asignado';
                event.allDay = true;
            }
            else if (shift.type === 'Franco') {
                event.title = 'Franco';
                event.allDay = true;
            } else {
                event.title = `${shift.startTime} - ${shift.endTime}`
                event.allDay = true;
            }

            // End date should take entire day if it doesn't have an endTime
            if (!shift.endTime) {
                event.end.setHours(23, 59, 59);
            }

            if (shift.isHoliday) {
                let holidayEvent = { id: null, start: event.start, end: event.end, allDay: true, display: 'background', isHoliday: true, backgroundColor: 'rgba(220, 53, 69,0.25)' }
                events.push(holidayEvent)
            }

            events.push(event);
        }

    })
    return events;
}

const AsistenciaService = {

    getShiftsHistory: async (setHistory, setLoading, setError) => {
        try {
            setLoading(true)
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/ShiftChanges`;
                axios.get(apiUrl, { headers: headers }).then(res => {
                    const response = res.data;
                    setHistory(response);
                    setLoading(false);
                    setError(null);
                }).catch(function (error) {
                    setLoading(false);
                    setError(error.response?.data);
                })
            })
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    },
    getShiftsHistoryFiltered: async (setHistory, setLoading, setError, estado) => {
        try {
            setLoading(true)
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/ShiftChanges?status=${estado}`;
                axios.get(apiUrl, { headers: headers }).then(res => {
                    const response = res.data;
                    setHistory(response);
                    setLoading(false);
                    setError(null);
                }).catch(function (error) {
                    setLoading(false);
                    setError(error.response?.data);
                })
            })
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    },
    getShifts: async (fromDate, toDate, setLoading, setShifts, setEvents, setError) => {
        try {
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/Shifts?fromDate=${fromDate}&toDate=${toDate}`;
                axios.get(apiUrl, { headers: headers }).then(res => {
                    const response = res.data;
                    setShifts(response);
                    let events = parseShiftsToEvents(response);
                    setEvents(events);
                    setLoading(false);
                    setError(null);
                }).catch(function (error) {
                    setLoading(false);
                    setError(error.response?.data);
                })
            })
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    },

    getWorkSchedules: async (duration, setWorkSchedules, setError, setLoading) => {
        try {
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                let apiUrl = `${url}/api/WorkSchedules`;
                if (duration) {
                    apiUrl += `?duration=${duration}`;
                }
                axios.get(apiUrl, { headers: headers }).then(res => {
                    setWorkSchedules(res.data);
                    if (setLoading) {
                        setLoading(false);
                    }
                }).catch(function (error) {
                    setError(error.response);
                    if (setLoading) {
                        setLoading(false);
                    }
                })
            })
        } catch (error) {
            setError(error);
            if (setLoading) {
                setLoading(false);
            }
        }
    },

    createShiftChangeRequest: async (shiftChange, setLoading, setSuccess, setError) => {
        try {
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/ShiftChange`;
                axios.post(apiUrl, shiftChange, { headers: headers }).then(res => {
                    setSuccess(res.data.descripcion);
                    setLoading(false);
                }).catch(function (error) {
                    setError(error.response.data.descripcion || error.response.data.message);
                    setLoading(false);
                })
            });
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    },

    reviewShiftChangeRequest: async (shiftChangeApproval, setLoading, setSuccess, setError) => {
        try {
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/ShiftChangeApproval`;
                axios.post(apiUrl, shiftChangeApproval, { headers: headers }).then(res => {
                    setSuccess(res.data.descripcion);
                    setLoading(false);
                }).catch(function (error) {
                    setError(error.response.data.descripcion);
                    setLoading(false);
                })
            });
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    },

    massiveAproval: async (shiftChangeApprovalIds, setLoading, setSuccess, setError, refreshCount) => {
        shiftChangeApprovalIds.forEach(id => {
            const shiftChangeApproval = {
                shiftChangeId: id,
                status: "A"
            };

            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/ShiftChangeApproval`;
                await axios.post(apiUrl, shiftChangeApproval, { headers: headers })
            });
        });
    },

    getSubordinatesSchedules: async (fromDate, toDate, employeesToSearch, beginTime, endTime, setLoading, setEmployeesSchedules, setError, setReload) => {
        try {
            setLoading(true)
            Auth.currentSession().then(async auth => {
                const from = fromDate.toString();
                const to = toDate.toString();
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                let apiUrl = `${url}/api/Employee/SubordinatesSchedules?fromDate=${from}&toDate=${to}`;
                if (employeesToSearch.length > 0) {
                    apiUrl += `&employeesIds=${employeesToSearch.join('&employeesIds=').toString()}`;
                }
                if (beginTime) {
                    apiUrl += `&beginTime=${beginTime}`;
                }
                if (endTime) {
                    apiUrl += `&endTime=${endTime}`;
                }

                axios.get(apiUrl, { headers: headers }).then(res => {
                    const response = res.data;
                    const result = [];
                    for (let employee of response) {
                        if (employee.shifts.length) {
                            employee.events = parseShiftsToEvents(employee.shifts);
                            result.push(employee)
                        }
                    }
                    setEmployeesSchedules(result)
                    setLoading(false);
                    setReload(false);
                    setError(null);
                }).catch(function (error) {
                    setLoading(false);
                    setError(error.response?.data?.message);
                })
            })
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    },
    getEmployeeSchedules: async (setShiftdisplay, setLoading, setError, setFetched, estado) => {
        try {
            setLoading(true)
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                let apiUrl = `${url}/api/Employee/GetApprovalPendants?status=${estado}`

                // eslint-disable-next-line no-undef
                axios.get(apiUrl, { headers: headers }).then(res => {
                    const response = res.data;
                    setShiftdisplay(response);
                    setFetched(true)
                    setError(null);
                    setLoading(false)
                }).catch(function (error) {
                    setError(error.response?.data);
                    setLoading(false);
                })
            })
        } catch (error) {
            setError(error);
        }
    },
    getSubordinatesData: async (setFetched, setSubordinates, setLoading, setError) => {
        try {
            setLoading(true)
            Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/Subordinates`;
                axios.get(apiUrl, { headers: headers }).then(res => {
                    const response = res.data;
                    setSubordinates(response);
                    setLoading(false);
                    setError(null);
                    setFetched(true)
                }).catch(function (error) {
                    setLoading(false);
                    setError(error.response?.data?.message);
                })
            })
        } catch (error) {
            setLoading(false);
            setError(error.response.data.message);
        }
    },

    getShiftForDate: async (date) => {
        try {
            return Auth.currentSession().then(async auth => {
                const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
                const apiUrl = `${url}/api/Employee/Shifts?fromDate=${date}&toDate=${date}`;
                return (await axios.get(apiUrl, { headers: headers })).data;
            })
        } catch (error) {
            return null;
        }
    },

    massiveApproval: async (ids = []) => {
        try {
            const auth = await Auth.currentSession();
            const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
            const apiUrl = `${url}/api/workSchedule/massiveApproval`;
            const resp = await axios.post(apiUrl, { ids }, { headers: headers });

            return resp.data;
        } catch (error) {
            return null;
        }
    },

    getMassiveApprovalProcess: async () => {
        try {
            const auth = await Auth.currentSession();
            const headers = { 'Content-Type': 'application/json', 'AccessToken': auth.getAccessToken().getJwtToken() }
            const apiUrl = `${url}/api/workSchedule/getMassiveApprovalProcess`;
            const resp = await axios.get(apiUrl, { headers: headers })

            return resp.data;
        } catch (error) {
            return null;
        }
    }
}

export default AsistenciaService;