import { useEffect } from "react";
import { CrudGenerator } from "../../../components/crudGenerator";
import type {
  Column,
  FormField,
} from "../../../components/crudGenerator/crudGenerator.types";
import { StepOneProps, Beneficiarie } from "../hooks/InsuranceApi.types";
import { useCompulsoryLifeInsuranceFetch } from "../hooks/useCompulsoryLifeInsuranceFetch";
import { useLunchScheduleProvider } from "../context/InsuranceContext";

export const StepOne: React.FC<StepOneProps> = ({
  onPercentageChange,
  data,
  setData,
}) => {
  const { dataRelationships } = useCompulsoryLifeInsuranceFetch();
  const { isLoading, dataBeneficiaries } = useLunchScheduleProvider();

  useEffect(() => {
    if (!dataBeneficiaries) return;

    const mapperBenef: any = dataBeneficiaries.map((benef) => ({
      id: benef.id,
      name: benef.name,
      lastname: benef.lastname,
      relationship: benef.relationship ? benef.relationship.type : "",
      percentage: benef.percentage,
      dni: benef.dni,
      streetName: benef.streetName,
      streetNro: benef.streetNro
    }));

    setData(mapperBenef);
  }, [dataBeneficiaries, setData]);
  useEffect(() => {
    const totalPercentage = data.reduce(
      (sum, item) => sum + Number(item.percentage),
      0
    );
    onPercentageChange(totalPercentage === 100);
  }, [data, onPercentageChange]);

  const onCreateITem = (newItem: Omit<Beneficiarie, "id">) => {
    const newId = data.length
      ? Math.max(...data.map((item) => item.id)) + 1
      : 1;
    const newUser = { ...newItem, id: newId };
    setData((prev) => {
      const updatedData = [...prev, newUser];
      return updatedData;
    });
  };

  const onUpdate = (updateItem: Beneficiarie) => {
    setData((prev) => {
      const updatedData = prev.map((item) =>
        item.id === updateItem.id ? updateItem : item
      );
      return updatedData;
    });
  };

  const onDelete = (id: string | number) => {
    setData((prev) => {
      const updatedData = prev.filter((item) => item.id !== id);
      return updatedData;
    });
  };

  const formFields: FormField[] = [
    {
      id: "name",
      label: "Nombre",
      required: true,
      type: "text",
      errorMsg: "Nombre es requerido",
    },
    {
      id: "lastname",
      label: "Apellido",
      required: true,
      type: "text",
      errorMsg: "Apellido es requerido",
    },
    {
      id: "relationship",
      label: "Vínculo",
      required: true,
      type: "select",
      errorMsg: "Vínculo es requerido",
      options:
        isLoading || !dataRelationships
          ? []
          : dataRelationships.map((relationship) => ({
            value: relationship.type,
            label: relationship.type,
          })),
    },
    {
      id: "percentage",
      label: "Porcentaje",
      required: true,
      type: "number",
      errorMsg: "Porcentaje es requerido",
      min: 0,
      max: 100,
    },
    {
      id: "dni",
      label: "DNI",
      required: true,
      type: "number",
      errorMsg: "DNI es requerido",
    },
    {
      id: "streetName",
      label: "Dirección(Calle)",
      required: true,
      type: "text",
      errorMsg: "Dirección es requerida",
    },
    {
      id: "streetNro",
      label: "Dirección(Número)",
      required: true,
      type: "text",
      errorMsg: "Número de dirección es requerida",
    },
  ];

  const columns: Column<Beneficiarie>[] = [
    {
      accessor: "name",
      header: "Nombre",
      className: "text-center",
    },
    {
      accessor: "lastname",
      header: "Apellido",
      className: "text-center",
    },
    {
      accessor: "relationship",
      header: "Vínculo",
      className: "text-center",
    },
    {
      accessor: "percentage",
      header: "Porcentaje",
      className: "text-center",
    },
    {
      accessor: "dni",
      header: "DNI",
      className: "text-center",
    },
    {
      accessor: "streetName",
      header: "Dirección(calle)",
      className: "text-center",
    },
    {
      accessor: "streetNro",
      header: "Dirección(Número)",
      className: "text-center",
    },
  ];

  return (
    <div className="box w-100">
      <h3>Beneficiarios</h3>
      
      <p>Tène en cuenta las siguientes recomendaciones para la carga de los beneficiarios:</p>
      <p>
        -	Los beneficiarios que designes deben ser mayor de 18 años
        -	Podes designar cualquier persona, sin necesidad de que sea un familiar
        -	La suma de los porcentajes distribuidos entre los beneficiarios debe sumar 100%
      </p>
      <CrudGenerator<Beneficiarie>
        columns={columns}
        dataTable={data}
        formFields={formFields}
        onCreate={onCreateITem}
        onEdit={onUpdate}
        onDelete={onDelete}
        titleModalCreateEdit="beneficiario"
        labelButtonCreate="Agregar beneficiario"
      />
    </div>
  );
};
