import React, { useRef, useEffect, FC } from 'react';

interface PopoverProps {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  children: React.ReactNode;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

export const Popover: FC<PopoverProps> = ({
  isOpen,
  onClose,
  anchorEl,
  children,
  width = 'auto',
  height = 'auto',
  style
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node) && 
          anchorEl && !anchorEl.contains(event.target as Node)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen, anchorEl, onClose]);

  if (!isOpen || !anchorEl) return null;

  const anchorRect = anchorEl.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  const popoverStyle: React.CSSProperties = {
    position: 'absolute',
    top: anchorRect.bottom + scrollTop,
    left: anchorRect.left + scrollLeft,
    width,
    height,
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    border: '1px solid #dee2e6',
    zIndex: 1050,
    padding: '1rem',
    marginTop: '0.5rem',
    ...style
  };

  return (
    <div ref={popoverRef} style={popoverStyle} className="popover show">
      {children}
    </div>
  );
}; 