/* eslint-disable no-undef */
import { useContext } from "react";
import "dotenv/config";
import Navbar from "./components/elements/navbar";
import GlobalNavbar from "./components/elements/global-navbar";
import GlobalHeader from "./components/elements/global-header";
import { Routes, Route, useLocation } from "react-router-dom";
import { Context } from "./context";
import Footer from "./components/elements/footer";
import ModalFirstLogin from "./components/elements/modal-first-login";
import ModalNotUserInfo from "./components/elements/modal-not-user-info";
import components from "./components/components";
import RequireFirstLogin from "./components/firstLogin/require-first-login";
import SignOutInactivity from "./components/elements/auto-signout";
import CompletedHiringModal from "./components/elements/completed-hiring-modal";
import Header from "./components/elements/header";
import Loading from "./components/elements/loading";
import ErrorScreen from "./components/commonComponents/error-screen/error-screen";
import { Modal } from "./components/commonComponents/Modal";
import ModalOnboarding from "./components/Onboarding/components/modalOnboarding";

const App = () => {
  const location = useLocation();
  const {
    active,
    step,
    appState,
    firstLoginData,
    pais,
    menuItems,
    someLoading,
    someError,
    isPublicUrl,
    signIn,
    signOut,
  } = useContext(Context);

  const dinamicRouter = (item) => {
    if (item.component !== "") {
      let DinamicComponent = null;
      if (item.dflt_component) {
        DinamicComponent = components[item.component];
      } else {
        DinamicComponent = components[item.component + "-" + pais];
      }
      if (item.publico) {
        const DinamicComponent = components[item.component];
        return <Route path={item.url} element={<DinamicComponent />} />;
      } else {

        // Si esta marcado como hidden es una ruta que no renderiza Menu
        if (item.hidden) {
          return <Route path={item.url} element={<DinamicComponent />} />;
        }

        if (pais === "CL") {
          return (
              <Route
                path={item.url + "/*"}
                element={<DinamicComponent />}
              />
          );
        }
        return (
          <Route element={<RequireFirstLogin signIn={signIn} />}>
            <Route
              exact
              path={item.url + "/*"}
              element={<DinamicComponent />}
            />
          </Route>
        );
      }
    }
  };
  return (
    (!someLoading && someError.etype && !isPublicUrl && <ErrorScreen {...someError} />) ||
    (!menuItems && someLoading && !someError.etype && <Loading />) ||
    (!menuItems && !someLoading && someError.etype && (
      <ErrorScreen {...someError} />
    )) ||
    (menuItems && <> 
      {
        isPublicUrl ? 
          <Routes>
            {menuItems && menuItems.map((item) => dinamicRouter(item))}{" "}
          </Routes>
        : 
          <>
            {
              pais === "CL" ? 
                <>
                  {location.pathname !== "/" && <Header signOut={signOut} />}
                  <main id="main" className="wrapper d-flex min-vh-100" >
                    {location.pathname !== "/" && firstLoginData && appState && <Navbar />}
                    <div id="content"
                      className={
                        (step === 0 || step > 9) &&
                          location.pathname === "/ficha-ingreso"
                          ? "container"
                          : active
                            ? "active"
                            : null
                      }
                    >
                      {(someLoading && <Loading />) ||
                        (someError.etype && <ErrorScreen {...someError} />) || (
                          <SignOutInactivity
                            time={process.env.REACT_APP_TIMEOUT || 300000}
                            signOut={signOut}
                          >
                            <Routes>
                              {appState &&
                                appState.map((i, index) => {
                                  const DinamicComponent =
                                    i.componente !== undefined
                                      ? components[i.componente]
                                      : null;
                                  return (
                                    DinamicComponent !== undefined && (
                                      <Route
                                        key={index}
                                        path={i.url + "/*"}
                                        element={<DinamicComponent />}
                                      />
                                    )
                                  );
                                })}
                            </Routes>
                          </SignOutInactivity>
                        )}
                      <ModalNotUserInfo signOut={signOut} />
                      <CompletedHiringModal signOut={signOut} />
                      {firstLoginData && (
                        <ModalFirstLogin firstLoginData={firstLoginData} />
                      )}
                    </div>
                  </main>
                  {!isPublicUrl && <Footer />}
                </>
                : 
                <>
                  <GlobalHeader signOut={signOut} />
                  <main id={"main"} className="wrapper d-flex">
                    <GlobalNavbar />
                    <div
                      id="content"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <SignOutInactivity
                        time={process.env.REACT_APP_TIMEOUT || 300000}
                        signOut={signOut}
                      >
                        <Routes>
                          {menuItems.map((item) => {
                            if (!item.haschildrens) {
                              let newItem = item;
                              if (item.Maintenance == false) {
                                newItem = {
                                  ...item,
                                  component: (item.component = "MaintanceComponent"),
                                  dflt_component: (item.dflt_component = true),
                                };
                              }
                              return dinamicRouter(item);
                            }
                            if (item.haschildrens) {
                              return item.subitems.map((subitem) => {
                                let newSubitem = subitem;
                                if (subitem.Maintenance == false) {
                                  newSubitem = {
                                    ...subitem,
                                    component: (subitem.component =
                                      "MaintanceComponent"),
                                    dflt_component: (subitem.dflt_component = true),
                                  };
                                }
                                return dinamicRouter(subitem);
                              });
                            }
                          })}
                        </Routes>
                      </SignOutInactivity>
                    </div>
                  </main>
                </>
            }
          </>
      }
        <Modal />
        <ModalOnboarding />
      </>
    )
  )
}

export default App;
