import React, { useEffect, useState } from "react";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { Step } from "../../../components/step";
import warningMessage from "../../../components/SharedComponents/warning-message";
import confirmMessage from "../../../components/SharedComponents/confirm-message";
import { Beneficiarie } from "../hooks/InsuranceApi.types";
import { useLunchScheduleProvider } from "../context/InsuranceContext";

interface StepInsuranceProps {
  onBack?: () => void;
}

export const StepInsurance: React.FC<StepInsuranceProps> = ({ onBack }) => {
  const [isPercentageValid, setIsPercentageValid] = useState(false);
  const [data, setData] = useState<Beneficiarie[]>([]);
  const {
    setCurrentStep,
    createInsuranceRequest,
    isErrorRequest,
    dataInsuredStatus,
    currentStep,
    setCurrentView
  } = useLunchScheduleProvider();

  useEffect(() => {
    if (isErrorRequest) {
      warningMessage(null, "Error al crear la solicitud");
      setCurrentView("main");
      setCurrentStep(1);
    }
  }, [isErrorRequest])

  const steps = [
    {
      label: "1",
      description: "Carga de datos",
      component: (
        <StepOne
          onPercentageChange={(isValid) => setIsPercentageValid(isValid)}
          data={data}
          setData={setData}
        />
      ),
    },
    {
      label: "2",
      description: "Validación",
      component: <StepTwo />
    },
    {
      label: "3",
      description: "Firma",
      component: <StepThree />
    },
  ];

  const transformedData = data.map(({ id, dni, percentage, ...rest }) => ({
    ...rest,
    dni: Number(dni),
    percentage: Number(percentage),
  }));

  const handleSubmit = async () => {
    if (isPercentageValid) {
      confirmMessage(() => {
        createInsuranceRequest({
          beneficiaries: transformedData,
        });
        setCurrentStep((prev) => Math.min(prev + 1, steps.length));
      },
        "Al aceptar ya no podrá realizar modificaciones. Te llegara un correo a **" + (dataInsuredStatus?.email.toLocaleLowerCase()) + "** cuando este listo el formulario con la información actualizada de la designación de beneficiarios para tu firma."
      )
    } else {
      warningMessage(
        null,
        "La cantidad de beneficiarios existentes deben sumar 100%"
      );
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 5rem",
        }}
      >
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Step
              label={step.label}
              isActive={currentStep === index + 1}
              isCompleted={index + 1 < currentStep}
              description={step.description}
            />

            {index < steps.length - 1 && (
              <div
                style={{
                  flex: 1,
                  marginTop: "40px",
                  height: "2px",
                  backgroundColor:
                    index + 1 < currentStep ? "#28a745" : "#e9ecef",
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="step-content">{steps[currentStep - 1].component}</div>

      {currentStep === 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "1rem",
          }}
        >
          <button onClick={onBack} className="btn btn-secondary">
            Volver
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};
