import React, { useState, useEffect } from "react";
import Service from "../services/evaluacion-empleados-service";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../services/utiles";
import { Link } from "react-router-dom";
import PaginatorTable from "./elements/paginator-table";

const PerformanceEvaluation = () => {
    let navigate = useNavigate();
    const [data, setData] = useState(false);
    const [estatus, setStatus] = useState("Cargando...");
    const emplid = localStorage.getItem("emplid");
    const rowPERIODO = []
    const rowTIPO = []
    const rowFINAL = []
    const rowEVALUADOR = []
    const rowFECHA = []
    const rowDESCRIPCION = []
    const [itemPaginador, setItemPaginador] = useState(null)
    const [resetOffset, setResetOffset] = useState(false)
    const [encabezados, setEncabezados] = useState(null)

    useEffect(() => {
        if (data != undefined && data !== 2 && data != false && data !== 1) {
            data.map(data => {
                let row = Object.values(data);
                if (Array.isArray(row) && row.length > 0) {
                    rowPERIODO.push(decrypt(row[2]));
                    rowTIPO.push(decrypt(row[3]));
                    rowFINAL.push(decrypt(row[7]));
                    rowEVALUADOR.push(decrypt(row[8]));
                    rowFECHA.push(row[1])
                    rowDESCRIPCION.push(row[10]);
                }
            });

            if (rowPERIODO.length > 0 || rowTIPO.length > 0 || rowFINAL.length > 0 || rowEVALUADOR.length > 0 || rowFECHA.length > 0 || rowDESCRIPCION.length > 0) {
                setEncabezados(rowFECHA)
                setItemPaginador(
                    [
                        {
                            title: "FECHA REVISIÓN",
                            rows: rowFECHA,
                        },
                        {
                            title: "PERIODO",
                            rows: rowPERIODO,
                        },
                        {
                            title: "TIPO EV.",
                            rows: rowTIPO,
                        },
                        {
                            title: "FINAL",
                            rows: rowFINAL,
                        },
                        {
                            title: "DESCRIPCIÓN",
                            rows: rowDESCRIPCION,
                        },
                        {
                            title: "EVALUADOR",
                            rows: rowEVALUADOR,
                        },
                    ]
                )
            }
        }
    }, [data])

    useEffect(() => {
        if (emplid !== null) Service.getemplEvalTable(emplid, setData, setStatus);
        else navigate("/");
    }, [emplid, navigate]);
    return (
        <>
            <nav className="breadcrumbs" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/inicio">
                            <i style={{ pointerEvents: "none" }} className="fa fa-home" aria-hidden="true"></i> Inicio
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Evaluación de Desempeño
                    </li>
                </ol>
            </nav>
            <section className="evaluacion-de-desempeno">
                <h1>Evaluación de Desempeño</h1>
                <div className="box mb-4">
                    <div className="tit-group d-flex justify-content-between">
                        <h2 className="h4 tit-section">Detalle Evaluaciones</h2>
                    </div>
                    {encabezados && itemPaginador ?
                        <PaginatorTable itemsPerPage={5} items={itemPaginador} header={encabezados} constHeader={'Fecha de revision'} setResetOffset={setResetOffset} resetOffset={resetOffset}></PaginatorTable>
                        : estatus}
                </div>
            </section>
        </>
    );
};
export default PerformanceEvaluation;
