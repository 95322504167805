import clsx from "../../utils/clsx";
import { TabProps } from "./tab.types";
import { FaHeart, FaShieldAlt, FaCross } from "react-icons/fa";
export const Tab = (props: TabProps) => {
  const {
    label,
    children,
    onClick,
    selected,
    icon,
    variant = "default",
    disabled = false,
    ...rest
  } = props;

  const tabStylesLine: React.CSSProperties = {
    outline: "none",
    boxShadow: selected ? "0 3px 0 0 #0169b5" : "none",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    transition: "box-shadow 0.3s ease, color 0.3s ease",
    color: selected ? "#0169b5" : "inherit",
    fontSize: "20px",
    textAlign: "center",
    opacity: disabled ? 0.5 : 1,
  };

  const iconsNode = {
    plus: <FaHeart />,
    hand: <FaShieldAlt />,
    edit: <FaCross />,
  };

  const tabStyles = {
    line: {
      tab: tabStylesLine,
      icon: { marginBottom: "4px" },
    },
    default: {
      tab: {},
      icon: { marginBottom: "0" },
    },
  };

  return (
    <div
      className={clsx(
        "nav-item flex-grow-1",
        variant !== "line" && " has-icon"
      )}
    >
      <button
        {...rest}
        role="tab"
        aria-selected={selected}
        onClick={onClick}
        style={tabStyles[variant].tab}
        className={`nav-link w-100 ${selected ? "active" : ""}`}
        disabled={disabled}
      >
        {icon && <span style={tabStyles[variant].icon}>{iconsNode[icon]}</span>}
        <span>{label}</span>
      </button>
    </div>
  );
};
