import React from "react";

interface StepProps {
  label: string;
  isActive: boolean;
  isCompleted: boolean;
  onClick?: () => void;
  description: string;
}

export const Step: React.FC<StepProps> = ({ label, isActive, isCompleted, onClick, description }) => {
  return (
    <div
      onClick={onClick}
      style={{
        marginTop: "30px",
        marginBottom: "30px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        color: isActive ? "#0169b5" : isCompleted ? "#28a745" : "#6c757d",
        fontWeight: isActive ? "bold" : "normal",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "1.5rem",
          height: "1.5rem",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "0.5rem",
          backgroundColor: isCompleted
            ? "#28a745"
            : isActive
              ? "#0169b5"
              : "#e9ecef",
          color: isCompleted || isActive ? "#fff" : "#6c757d",
          fontSize: "1rem",
        }}
      >
        {isCompleted ? "✔" : isActive ? `${label}` : `${label}`}
      </div>

      <span style={{ position: 'relative', left: -5 }}>{description}</span>
    </div>
  );
};

export default Step;
