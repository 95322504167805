import React, { FC, Children } from "react";
import { CarouselIconProps, DefaultCarouselIconProps, CarouselIconComponentProps } from "./Helpers/interfaces/carouselIcon";
import { Link } from 'react-router-dom';

const CarouselIcon : FC<CarouselIconComponentProps> = ({ id, chunkSize, data : dataToDisplay } : CarouselIconComponentProps ) => {
    const chunks : CarouselIconProps[][] = [];
    
    for (let i = 0; i < dataToDisplay.length; i += chunkSize) {
        chunks.push(dataToDisplay.slice(i, i + chunkSize));
    }
    return (
        <div id={"carousel-"+id} className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {chunks.map((group : CarouselIconProps[], index : number) => (
                <div key={index} style={{overflowY: "auto"}} className={`carousel-item px-5 ${index === 0 ? 'active' : ''}`}>
                    <div className="d-flex flex-row flex-nowrap justify-content-around">
                    {Children.toArray(group.map((item : CarouselIconProps) => (
                        React.isValidElement(item) ? item
                        : renderIconCarouselItem( item as DefaultCarouselIconProps)
                    )))}
                    </div>
                </div>
                ))}
            </div>
            {
                    dataToDisplay.length !== chunkSize  &&
                <>
                    <button className="carousel-control-prev h-100 d-block ms-2" type="button" data-bs-target={"#carousel-"+id} data-bs-slide="prev">
                        <i className="fa fa-chevron-left color-dark" aria-hidden="true"></i>
                        <span className="visually-hidden">Anterior</span>
                    </button>
                    <button className="carousel-control-next h-100 d-block me-2" type="button" data-bs-target={"#carousel-"+id} data-bs-slide="next">
                        <i className="fa fa-chevron-right color-dark" aria-hidden="true"></i>
                        <span className="visually-hidden">Siguiente</span>
                    </button>
                </>
            }
        </div>
    );
};

const renderIconCarouselItem = ( item: DefaultCarouselIconProps ) => {
    return <div className={`d-flex m-2 mx-3`}>
        <Link to={item.url} className="btn btn-outline-gray btn-lg text-decoration-none d-flex align-items-center w-100 text-start m-0">
            <i className={`fa ${item.icon} d-block fa-lg color-primary fs-3`} aria-hidden="true" title={item.title}></i>
            <span className="ps-2 color-dark fw-bold h5 m-0">{item.title}</span>
        </Link>
    </div>
}

export default CarouselIcon;
