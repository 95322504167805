import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../context";
import {
  WidgetBanner,
  WidgetCarouselIcon,
  WidgetSlider,
  WidgetTable,
  WidgetTask,
} from "../../commonComponents/Widget";
import {
  getBannerBenefits,
  getNextBirthdays,
  getShortcuts,
  getVacationsAndLicenses,
  getBannerNews,
} from "../services/homeServices";
import newsletterInicio from "../../../resources/images/banner-suscripcion-inicio.png";
import Box from "../../commonComponents/Box";
import EthicalCodeServices from "../../../services/codigo-etica/service";
import DatosPersonalesServices from "../../../services/datos personales col/datos-personales-services";

const HomeCO: FC = () => {
  const { setShowNewsletter } = useContext<any>(Context);

  const navigate = useNavigate();

  const getTaskEthicalCode = async () => {
    const response = await EthicalCodeServices.getEhicalCode();
    const status = response.data.status;

    if (status === "Vencido" || status === "Pendiente") return {
      id: "ETICA-EMPLOYEE",
      country: "CO",
      module_name: "Codigo de Etica",
      component: "/col/codigoetica",
      created_at: response.data.expirationDate,
      parameters: "",
      title: "Codigo de Etica pendiente de firma"
    }

    return null;
  };

  const getTaskIsUpdatePersonalData = async () => {
    const getIsUpdatePersonalData = await DatosPersonalesServices.getDeclaration();

    if (!getIsUpdatePersonalData.isUpdated) {
      return {
        id: "GETUPDATE-PERSONALDATA",
        country: "CO",
        module_name: "Datos Personales",
        component: "/col/datospersonales",
        created_at: getIsUpdatePersonalData.lastDate,
        parameters: "",
        title: "Datos personales pendiente de Actualizacion"
      };
    }

    return null;
  }

  const getTasks = async () => {
    const ethicalCode = await getTaskEthicalCode();
    const updatePersonalData = await getTaskIsUpdatePersonalData()

    const tasks = [];

    if (ethicalCode) tasks.push(ethicalCode);
    if (updatePersonalData) tasks.push(updatePersonalData);

    return tasks;
  }

  return (
    <>
      <WidgetCarouselIcon
        id="accesos-directos"
        width="3/3"
        box={false}
        data={() => getShortcuts()}
      />

      <WidgetBanner
        id="noticias-destacadas"
        width="2/3"
        box={false}
        data={() => getBannerNews()}
      />

      <WidgetSlider
        id="beneficios-destacados"
        data={() => getBannerBenefits()}
        width="3/3"
        box={false}
      />

      <Box width="3/3" box={false}>
        <div
          className="pointer w-100"
          onClick={(e) => {
            e.preventDefault();
            setShowNewsletter(true);
            navigate("/suscripciones");
          }}
        >
          <img
            src={newsletterInicio}
            className="img-fluid p-0 w-100 rounded-4"
            alt="logo"
          />
        </div>
      </Box>

      <WidgetTable
        id="vacations-and-licenses"
        title="Mi Equipo"
        data={() => getVacationsAndLicenses()}
        width="2/3"
        box={true}
      />

      <WidgetTable
        id="proximos-cumpleanos"
        title="Próximos Cumpleaños"
        data={() => getNextBirthdays()}
        width="1/3"
        box={true}
      />

      <WidgetTask
        id="task"
        data={() => getTasks()}
        width="1/3"
        box={true}
      />
    </>
  );
};

export default HomeCO;
