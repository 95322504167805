import { createContext, useContext, useEffect, useState } from "react";
import {
  InsuranceContextType,
  InsuranceProviderProps,
} from "../hooks/InsuranceApi.types";
import { useCompulsoryLifeInsuranceFetch } from "../hooks/useCompulsoryLifeInsuranceFetch";

export const InsuranceDataContext = createContext<
  InsuranceContextType | undefined
>(undefined);

export const InsuranceProvider = (props: InsuranceProviderProps) => {
  const { children } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const {
    createInsuranceRequest,
    dataInsuredStatus,
    dataInsuredPreview,
    isLoading,
    dataBeneficiaries,
    handleSignDocumentRequest,
    isError: isErrorRequest,
  } =
    useCompulsoryLifeInsuranceFetch();
  const [currentView, setCurrentView] = useState<"main" | "step">("main");

  const status: { [key: string]: number } = {
    Nuevo: 2,
    Enviado: 3,
    Conforme: 1,
  };

  useEffect(() => {
    if (dataInsuredStatus?.status) {
      setCurrentStep(status[dataInsuredStatus.status]);
      setCurrentView(dataInsuredStatus.status === "Conforme" ? "main" : "step");
    }
  }, [dataInsuredStatus]);

  const onDownload = () => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${dataInsuredPreview}`;
    link.download = "seguro_vida.pdf";
    link.click();
  };

  const contextValue = {
    setCurrentStep,
    createInsuranceRequest,
    isErrorRequest,
    handleSignDocumentRequest,
    currentStep,
    currentView,
    setCurrentView,
    onDownload,
    isLoading,
    dataBeneficiaries,
    dataInsuredStatus
  };

  return (
    <InsuranceDataContext.Provider value={contextValue}>
      {children}
    </InsuranceDataContext.Provider>
  );
};

export const useLunchScheduleProvider = () => {
  const insuranceDataContext = useContext(InsuranceDataContext);

  if (!insuranceDataContext) return {} as InsuranceContextType;

  return insuranceDataContext;
};
