import { useEffect, useState } from "react";
import { useAxiosFetch } from "../../../hooks";
import {
  CreateInsuredLifeDto,
  GetBeneficieriesApi,
  InsuredPreview,
  InsuredStatus,
  RelationshipApi,
  SignDocumentApi,
} from "./InsuranceApi.types";
import errorMessage from "../../../components/SharedComponents/error-message";
import successMessage from "../../../components/SharedComponents/success-message";

export const useCompulsoryLifeInsuranceFetch = () => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const [createInsuranceRequest, createInsuranceResponseData] =
    useAxiosFetch<CreateInsuredLifeDto>({
      url: `/api/life-insurance/beneficiaries`,
      method: "POST",
    });

  const [fetchSignDocumentApi, fetchSignDocumentApiData] =
    useAxiosFetch<SignDocumentApi>({
      url: `/api/life-insurance/sign-document-agreement`,
      method: "POST",
    });

  const [getbeneficiaries, getbeneficiariesData] = useAxiosFetch<
    GetBeneficieriesApi[]
  >({
    url: `/api/life-insurance/beneficiaries`,
    method: "GET",
  });

  const [getRelationships, getRelationshipsData] = useAxiosFetch<
    RelationshipApi[]
  >({
    url: `/api/life-insurance/relationships`,
    method: "GET",
  });

  const [getInsuredStatus, getInsuredStatusData] = useAxiosFetch<
    InsuredStatus
  >({
    url: `/api/life-insurance/insured-status`,
    method: "GET",
  });

  const [getInsuredPreview, getInsuredPreviewData] = useAxiosFetch<
    [InsuredPreview]
  >({
    url: `/api/life-insurance/preview-insurance`,
    method: "GET",
  });

  useEffect(() => {
    getbeneficiaries();
    getRelationships();
    getInsuredStatus();
    getInsuredPreview();
  }, []);

  const getInsuredStatusMapper = () => {
    if (getInsuredStatusData.data)
      return getInsuredStatusData.data
  };

  const getInsuredPreviewMapper = () => {
    if (getInsuredPreviewData.data)
      return getInsuredPreviewData.data
  };

  const relationshipsMapper = () => {
    if (getRelationshipsData.data)
      return getRelationshipsData.data.map((relationship) => ({
        id: relationship.id,
        type: relationship.type,
        createdAt: relationship.createdAt,
        updatedAt: relationship.updatedAt,
      }));
    return [];
  };

  const beneficiariesMapper = (): GetBeneficieriesApi[] => {
    if (getbeneficiariesData.data)
      return getbeneficiariesData.data.map((beneficierie) => ({
        id: beneficierie.id,
        name: beneficierie.name,
        lastname: beneficierie.lastname,
        percentage: beneficierie.percentage,
        dni: beneficierie.dni,
        streetName: beneficierie.streetName,
        streetNro: beneficierie.streetNro,
        relationshipId: beneficierie.relationshipId,
        insuredId: beneficierie.insuredId,
        createdAt: beneficierie.createdAt,
        updatedAt: beneficierie.updatedAt,
        relationship: getRelationshipsData.data?.find(
          (relationship) => relationship.id === beneficierie.relationshipId
        ) || { id: 0, type: '', createdAt: '', updatedAt: '' }
      }));
    return [];
  };

  const submitCreateBenefitRequest = async (data: CreateInsuredLifeDto) => {
    try {
      await createInsuranceRequest({
        data,
      });
      return { success: true, message: "Solicitud creada correctamente." };
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return { success: false, message: error.response.data.message };
      } else {
        return {
          success: false,
          message: "Error desconocido al crear la solicitud.",
        };
      }
    }
  };

  const submitSignDocumentRequest = async (data: SignDocumentApi) => {
    try {
      await fetchSignDocumentApi({ data });
      setSuccess(true);
      return { success: true, message: "Documento firmado correctamente." };
    } catch (error: any) {
      if (error.response?.data?.message) {
        setError(error.response.data.message);
        return { success: false, message: error.response.data.message };
      }
      setError("Error desconocido al firmar el documento.");
      return { success: false, message: "Error desconocido al firmar el documento." };
    }
  };

  useEffect(() => {
    if (error) {
      errorMessage(null, error);
      setError(null)
    }
  }, [error])
  
  useEffect(() => {
    if (success) {
      successMessage(null);
      setSuccess(false)
    }
  }, [success])

  const handleSignDocumentRequest = async (data: SignDocumentApi) => {
    const response = await submitSignDocumentRequest(data);
    if (response.success) {
      getInsuredStatus();
      getbeneficiaries();
      console.log(response.message);
    } else {
      console.error(response.message);
    }
  };

  const handleCreateBenefitRequest = async (data: CreateInsuredLifeDto) => {
    const response = await submitCreateBenefitRequest(data);
    if (response.success) {
      getbeneficiaries();
      console.log(response.message);
    } else {
      console.error(response.message);
    }
  };

  return {
    createInsuranceRequest: handleCreateBenefitRequest,
    handleSignDocumentRequest,
    dataRelationships: relationshipsMapper(),
    dataBeneficiaries: beneficiariesMapper(),
    dataInsuredStatus: getInsuredStatusMapper(),
    dataInsuredPreview: getInsuredPreviewMapper(),
    isLoading:
      createInsuranceResponseData.isLoading ||
      fetchSignDocumentApiData.isLoading ||
      getbeneficiariesData.isLoading ||
      getRelationshipsData.isLoading ||
      getInsuredStatusData.isLoading ||
      getInsuredPreviewData.isLoading,
    status:
      createInsuranceResponseData.status ||
      getbeneficiariesData.status ||
      getRelationshipsData.status,
    isError: createInsuranceResponseData.isError,
  } as const;
};
