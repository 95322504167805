import { ColumnsConfig } from "../../../components/table";
import { LayoutCard } from "../../teamDashboard/_components/employeeProfileSummary/LayoutCard";
import { StepInsurance } from "./StepsInsurance";
import { SpinerFullScreen } from "../../../components/commonComponents/SpinerFullScreen";
import { useLunchScheduleProvider } from "../context/InsuranceContext";

export const CompulsoryLifeInsurance = () => {
  const {
    currentView,
    setCurrentView,
    onDownload,
    isLoading,
    dataBeneficiaries,
  } = useLunchScheduleProvider();

  const columnsConfigDays: ColumnsConfig[] = [
    {
      accessor: "name",
      header: "Nombre",
      className: "text-center",
    },
    {
      accessor: "lastname",
      header: "Apellido",
      className: "text-center",
    },
    {
      accessor: "relationship.type",
      header: "Vínculo",
      className: "text-center",
    },
    {
      accessor: "percentage",
      header: "Porcentaje",
      className: "text-center",
    },
    {
      accessor: "dni",
      header: "DNI",
      className: "text-center",
    },
    {
      accessor: "streetName",
      header: "Dirección(Calle)",
      className: "text-center",
    },
    {
      accessor: "streetNro",
      header: "Dirección(Número)",
      className: "text-center",
    },
  ];

  return (
    <>
      {isLoading ? (
        <SpinerFullScreen />
      ) : (
        <>
          {currentView === "main" && (
            <>
              {dataBeneficiaries.length == 0 ? (
                <>
                  <div className="d-flex justify-content-end m-5">
                    <button
                      onClick={() => setCurrentView("step")}
                      className="btn btn-degradado"
                      type="button"
                    >
                      Actualizar Beneficiarios
                    </button>
                  </div>
                  <h3 style={{ textAlign: "center" }}>
                    No hay beneficiarios asociados
                  </h3>

                  <p>
                    Aquí podrás visualizar los beneficiarios asociados a tu seguro de vida obligatorio. En caso que no visualices o quieras actualizar la información, por favor presiona el botón “actualizar Beneficiarios” y carga los datos solicitados.
                  </p>
                </>
              ) : (
                <div className="box w-100 mt-5">
                  <h3>Beneficiarios</h3>
                  <div className="d-flex justify-content-end m-3">
                    <button
                      onClick={() => setCurrentView("step")}
                      className="btn btn-degradado"
                      type="button"
                    >
                      Modificar seguro
                    </button>
                  </div>
                  <LayoutCard
                    columnsConfig={columnsConfigDays}
                    data={dataBeneficiaries}
                  />
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "16px" }}
                  >
                    <button
                      onClick={() => onDownload()}
                      className="btn btn-primary"
                      type="button"
                    >
                      Descargar PDF
                    </button>
                  </div>
                </div>
              )}
            </>
          )}

          {currentView === "step" && (
            <StepInsurance onBack={() => setCurrentView("main")} />
          )}
        </>
      )}
    </>
  );
};
