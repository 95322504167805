import { FaTrash } from "react-icons/fa";
import { LunchScheduleProps } from "./hooks/LunchScheduleApi.types";
import { useLunchScheduleProvider } from "./context/LunchScheduleContext";

export const LunchScheduleModal = (props: LunchScheduleProps) => {
  const { dataPlacesToReserve } = useLunchScheduleProvider();
  const {
    modalData,
    handleSelectChange,
    handleDelete,
    handleSave,
    handleCloseModal,
    handleUpdate,
  } = props;

  const sortedModalData = [...modalData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const handleUpdateData = () => {
    sortedModalData.forEach((item) => {
      if (item.reservationPlace) {
        handleUpdate({
          date: item.date,
          reservationPlace: item.reservationPlace,
          id: item.id,
        });
      }
    });

    handleSave();
  };

  return (
    <div
      className="modal-overlay d-sm-flex justify-content-sm-center align-items-sm-center fixed-top"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
        alignContent: "center",
      }}
    >
      <div
        className="modal-content d-sm-flex h-auto flex-column justify-content-between rounded"
        style={{
          maxWidth: "700px",
          padding: "20px",
          backgroundColor: "#fff",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
          Seleccionar día y horario
        </h2>
        {sortedModalData.map((item, index) => (
          <div key={item.date}>
            <div className="mb-3">
              <div className="d-flex align-items-center gap-2">
                <span className="h4 col">{item.date}</span>
                <div style={{ flex: 2 }}>
                  <label className="btn-pointer small">Seleccionar Lugar</label>
                  <select
                    value={
                      item.reservationPlace ?? dataPlacesToReserve[0]?.placeName
                    }
                    onChange={(e) =>
                      handleSelectChange(item.date, e.target.value)
                    }
                    className="form-select w-50"
                    disabled={item.disabled}
                  >
                    {dataPlacesToReserve.map((place) => (
                      <option key={place.id} value={place.placeName}>
                        {place.placeName}
                      </option>
                    ))}
                  </select>
                </div>
                {!item.disabled && (
                  <button
                    className="btn"
                    style={{ color: "red" }}
                    onClick={() => handleDelete(item.id)}
                  >
                    <FaTrash />
                  </button>
                )}
              </div>
            </div>
            {index < modalData.length - 1 && (
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid grey",
                  margin: "15px 0",
                }}
              />
            )}
          </div>
        ))}
        <div className="d-flex justify-content-between mt-3">
          <button className="btn btn-primary" onClick={handleCloseModal}>
            Cancelar
          </button>
          <button className="btn btn-degradado" onClick={handleUpdateData}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};
