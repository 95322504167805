import React, { useState, ReactNode } from "react";
import { Pagination } from "./Pagination";
import styles from "../../resources/css/style-ARG.module.css";

interface PaginatedContainerProps {
  itemsPerPage: number;
  styrlePagination?: React.CSSProperties;
  classNamePagination?: string;
  children: ReactNode;
}

const PaginatedContainer: React.FC<PaginatedContainerProps> = ({
  itemsPerPage,
  styrlePagination,
  classNamePagination,
  children,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const items = React.Children.toArray(children);
  const totalItems = items.length;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedItems = items.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      <tbody>{selectedItems}</tbody>
      <tfoot >
        <tr>
          <td colSpan={9} className={`${styles["table_columna"]}`} >
            {totalItems > 0 && <Pagination
              elementsPerPage={itemsPerPage}
              page={currentPage}
              setPage={handlePageChange}
              totalItems={totalItems}
              style={styrlePagination}
              className={`${styles["pagination_container"]} text-secondary`}
            />}
          </td>
        </tr>
      </tfoot>
    </>
  );
};

export default PaginatedContainer;
