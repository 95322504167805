import React, { useState, useEffect } from 'react'
import styles from "../../resources/css/style-COL.module.css";
import DatosPersonalesServices from '../../services/datos personales col/datos-personales-services';

export default function Declaracion() {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        const fetchDeclaration = async () => {
            const response = await DatosPersonalesServices.getDeclaration();
                setIsChecked(response.isUpdated);
       };

        fetchDeclaration();
    }, []);

    const handleCheckboxChange = async (event) => {
        setIsChecked(event.target.checked);

        if (event.target.checked) {
            await DatosPersonalesServices.postDeclaration({ isChecked });
        }
    };

    return (
        <div className={`${styles["my-3"]}`}>
            {!isChecked &&
                <div className="alert alert-warning" >
                    <i className="fa fa-exclamation-triangle fa-fw fa-lg mt-1 me-2 ms-2" aria-hidden="true"></i> Se debe confirmar que los datos están actualizados.
                </div>
            }
            <form className={`${styles["form-check"]} ${styles["my-3"]}`}>
                <input
                    className={`${styles["form-check-input"]} ${styles["h5"]}`}
                    type="checkbox"
                    checked={isChecked}
                    id="acepto"
                    onChange={handleCheckboxChange}
                    disabled={isChecked}
                />
                <label
                    className={`${styles["form-check-label"]} ${styles["fw-normal"]}`}
                    htmlFor="acepto"
                >
                    Declaro que mis datos personales, Información de contacto y foto se encuentran actualizados.
                </label>

            </form>
        </div>
    )
}