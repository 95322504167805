import { urlsAws } from "../../../../resources/foo/api-endpoints";
import Auth from "@aws-amplify/auth";
import { typeBonus } from "../interfaces/interfaces";

type Body = { [key: string]: any } | FormData | null;
type Method = "GET" | "POST" | "PUT" | "DELETE";
type FetchData = {
  url: string;
  method?: Method;
  body?: Body;
  saveCache?: boolean;
  isFile?: boolean;
};

const urlsBonuses: Partial<Record<typeBonus, string>> = {
  premiohabitacional: urlsAws.bonusHome,
  "premio-excelencia-academica": urlsAws.bonusAcademicExc,
  taskHome: urlsAws.bonusHome,
  taskAcadExcellence: urlsAws.bonusAcademicExc,
};

const fetchData = async ({
  url = "",
  method = "GET",
  body = null,
}: FetchData) => {
  try {
    if (!url) return false;
    const auth = await Auth.currentSession();
    const headers: { [key: string]: string } = {
      AccessToken: auth.getAccessToken().getJwtToken(),
      Authorization: auth.getIdToken().getJwtToken(),
    };
    let requestOptions: RequestInit = {
      method,
      headers,
    };

    if (body instanceof FormData) {
      requestOptions.body = body;
    } else {
      headers["Content-Type"] = "application/json; charset=utf-8";
      requestOptions.body = body ? JSON.stringify(body) : null;
    }

    const response = await fetch(url, requestOptions);

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getBonus = async (parameters: string, type: typeBonus) => {
  const param = parameters ? "?" + parameters : "";
  const data = await fetchData({
    url: urlsBonuses[type] + param,
    method: "GET",
  });

  if (data.response) {
    return data;
  } else throw new Error("getBonus ", data);
};

export const postBonus = async (formData: FormData, type: typeBonus) => {
  const data = await fetchData({
    url: urlsBonuses[type] || "",
    method: "POST",
    body: formData,
  });

  if (data.response) {
    return {
      status: 200,
      statusText: data.response.status || "Ha ocurrido un error",
      valid: data.response.valid,
    };
  } else throw new Error("postBonus ", data);
};
