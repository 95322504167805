import { useState } from "react";
import { FaTrash, FaEdit } from 'react-icons/fa';
import { CrudGeneratorProps } from "./crudGenerator.types";
import { ColumnsConfig, Table } from "../table";
import { useCrud } from "./hooks/useCRUD";
import { ModalItemForm } from "./ModalItemForm";
import { ModalDeleteItem } from "./ModalDeleteItem";

export const CrudGenerator = <T extends { id: string | number }>(props: CrudGeneratorProps<T>) => {
  const {
    columns,
    dataTable: initialData,
    formFields,
    onCreate,
    onDelete,
    titleModalDelete,
    titleModalCreateEdit,
    onEdit,
    labelButtonCreate = "Crear ITem"
  } = props;

  const { dataList, isLoading } = useCrud<T>(initialData);

  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState<string | number | null>(null);
  const [editItem, setEditItem] = useState<T | null>(null);

  const columnsConfig = [
    ...columns.map(column => (
      {
        accessor: column.accessor.toString(),
        header: column.header,
        className: column.className
      }
    )),
    ...(onEdit || onDelete ? [{
      accessor: "actions",
      header: "Acciones",
      className: "text-start"
    }] : [])
  ] as ColumnsConfig[]

  const toggleModalForm = (item?: T) => {
    setIsModalFormOpen(!isModalFormOpen)
    item && setEditItem(item)
  };
  const openModalDelete = (id: string | number) => {
    setModalDeleteOpen(id)
  };
  const clonseModalDelete = () => {
    setModalDeleteOpen(null)
  };

  const adaptedDataTable = dataList.map(item => ({
    ...item,
    ...(onDelete || onEdit ? {
      actions: (
        <div className="d-flex gap-2">
          {onEdit && formFields && <FaEdit
            className="color-primary"
            onClick={() => toggleModalForm(item)}
          />}

          {onDelete && <FaTrash className="color-red" onClick={() => openModalDelete(item.id)} />}
        </div>
      )
    } : {})
  }));

  return (
    <div>
      <div className="d-flex justify-content-end m-3">
        {
          formFields && onCreate &&
          <button
            onClick={() => toggleModalForm()}
            className="btn btn-degradado"
            type="button"
          >
            {labelButtonCreate}
          </button>
        }
      </div>

      <Table
        columnsConfig={columnsConfig}
        data={adaptedDataTable}
        isLoading={isLoading}
      />

      {
        formFields && (
          <ModalItemForm<T>
            show={isModalFormOpen}
            handleClose={toggleModalForm}
            formFields={formFields}
            createItem={onCreate}
            editedItem={editItem}
            setEditItem={setEditItem}
            updateItem={onEdit}
            titleModalCreateEdit={titleModalCreateEdit}
          />
        )
      }

      {
        onDelete &&
        modalDeleteOpen &&
        <ModalDeleteItem
          onDelete={onDelete}
          idItem={modalDeleteOpen}
          handleClose={clonseModalDelete}
          titleModalDelete={titleModalDelete}
        />
      }
    </div>
  )
}


