import * as Yup from "yup";
import { type IDynamicValidations } from "../../../RemoteJob/interfaces/interfaces";
import { validarRut } from "./rutHelpers";
import moment from "moment";
import { FILE_UNIT_MB_SIZE_LIMIT } from "../constant/global";
import { bytesToMB } from "../../../../../services/utiles";

const EMAIL_REGEX: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_CL_REGEX: RegExp = /^\+569\d{8}$/;
const LETTERS_REGEX: RegExp =
  /^(?:(?=.*[A-Za-zÁÉÍÓÚáéíóúÑñ'-])[A-Za-zÁÉÍÓÚáéíóúÑñ' -]*)?$/;
const TYPES_FILE = [".pdf", ".jpg", ".png", ".jpeg"];

const getExtensionFile = (nombreArchivo: string) => {
  const partes = nombreArchivo.split(".");
  if (partes.length === 1) {
    return "";
  }
  return partes[partes.length - 1].toLowerCase();
};

export const VALIDATIONS_FUNCTIONS: any = {
  text: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaString(inputValidation),

  phone: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaString(inputValidation),

  email: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaString(inputValidation),

  textarea: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaString(inputValidation),

  date: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaDate(inputValidation),

  select: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaString(inputValidation),

  number: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaNumber(inputValidation),

  checkbox: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaBoolean(inputValidation),

  radio: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaString(inputValidation),

  file: (inputValidation: IDynamicValidations[]) =>
    validationsSchemaFile(inputValidation),
};

export const validationsSchemaString = (
  validations: IDynamicValidations[]
): Yup.StringSchema => {
  let schema: Yup.StringSchema = Yup.string();

  for (const rule of validations) {
    const { type, description, value } = rule;

    if (type === "required") {
      schema = schema.required(
        description && description !== ""
          ? description
          : "El campo es requerido."
      );
    }
    if (type === "justLetters") {
      schema = schema.matches(LETTERS_REGEX, "Se admiten solo letras.");
    }

    if (type === "rutChileno") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Por favor, ingrese un rut válido. (Ej: 11111111-1)",
        (input) => validarRut(input)
      );
    }
    if (type === "email") {
      schema = schema.test(
        type,
        description && description !== "" ? description : "Email no válido.",
        (input) => EMAIL_REGEX.test(input ?? "")
      );
    }
    if (type === "minLength") {
      schema = schema.min(
        (rule as any).value || 1,
        `Mínimo de ${value || 1} caracteres.`
      );
    }
    if (type === "maxLength") {
      schema = schema.max(
        (rule as any).value || 55,
        `Máximo de ${value || 55} caracteres.`
      );
    }
    if (type === "minWords") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Numero de palabras inválido.",
        (input) => {
          const inputValue = input ?? "";
          const words = inputValue.trim().split(" ");
          return words.length >= value;
        }
      );
    }
    if (type === "maxWords") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Numero de palabras inválido.",
        (input) => {
          const inputValue = input ?? "";
          const words = inputValue.trim().split(" ");
          return words.length <= value;
        }
      );
    }
    if (type === "phoneNumberCL") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Número no válido, debe iniciar con +569",
        (input) => {
          if (!input) return false;
          return PHONE_CL_REGEX.test(input.toString());
        }
      );
    }
  }

  return schema;
};

export const validationsSchemaNumber = (
  validations: IDynamicValidations[]
): Yup.NumberSchema => {
  let schema: Yup.NumberSchema = Yup.number().transform(
    (value, originalValue) => {
      return originalValue === "" ? undefined : value;
    }
  );

  for (const rule of validations) {
    let { type, description, value } = rule;

    if (type === "required") {
      schema = schema.required(
        description && description !== ""
          ? description
          : "El campo es requerido."
      );
    }

    if (type === "minLength") {
      const largeToValidate = Number("1".repeat(value));
      schema = schema.min(
        largeToValidate,
        description && description !== ""
          ? description
          : `El mínimo es ${value || 0}`
      );
    }

    if (type === "maxLength") {
      schema = schema.max(
        999999999,
        description && description !== ""
          ? description
          : `El máximo es ${value || 9999}`
      );
    }

    if (type === "lessThan") {
      schema = schema.lessThan(
        value || 0,
        description && description !== ""
          ? description
          : `Valor ingresado debe ser menor a ${value || 0}`
      );
    }

    if (type === "moreThan") {
      schema = schema.moreThan(
        value || 9999,
        description && description !== ""
          ? description
          : `Valor ingresado debe ser mayor a ${value || 9999}`
      );
    }

    if (type === "between") {
      const [min, max] = (value || "-1:-1").split(":").map(Number);
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : `El valor ingresado debe estar entre ${min} y ${max}`,
          (val) => {
            if (min === -1 || max === -1) return false;
            if (val === undefined || val === null) return true;
            return val >= min && val <= max;
          }
      );
    }

    if (type === "phoneNumberCL") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Número no válido, debe iniciar con +569",
        (input) => {
          if (!input) return false;
          return PHONE_CL_REGEX.test(input.toString());
        }
      );
    }

    if (type === "number") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "El campo debe ser un número mayor a 0",
        (value) => value !== undefined && value > 0
      );
    }
  }
  return schema;
};

export const validationsSchemaBoolean = (
  validations: IDynamicValidations[]
): Yup.BooleanSchema => {
  let schema: Yup.BooleanSchema = Yup.boolean().transform(
    (value, originalValue) => {
      // Convertir valores no booleanos en `false`
      return originalValue === "" || originalValue == null ? false : value;
    }
  );

  for (const rule of validations) {
    const { type, description } = rule;

    if (type === "required") {
      schema = schema.oneOf(
        [true],
        description && description !== ""
          ? description
          : "El campo es requerido."
      );
    }
  }

  return schema;
};

export const validationsSchemaDate = (
  validations: IDynamicValidations[]
): Yup.DateSchema => {
  let schema = Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("El campo es requerido.");

  for (const rule of validations) {
    const { type, description, value } = rule;

    if (type === "required") {
      schema = schema.required(
        description && description !== ""
          ? description
          : "El campo es requerido."
      );
    }

    if (type === "dateEqual") {
      const dateToCompare = moment(value);

      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : `Fecha no igual a ${dateToCompare.format("DD-MM-YYYY")}`,
        (input) => {
          if (!input || !value) return false;

          const dateForm = moment(input);

          return dateForm.isSame(dateToCompare);
        }
      );
    }

    if (type === "dateEqualToForm") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Fechas no son iguales",
        function (input) {
          if (!input || !value) return false;

          const dateInput = moment(this.resolve(Yup.ref(value)));
          const dateToCompare = moment(input);

          return dateInput.isSame(dateToCompare);
        }
      );
    }

    if (type === "dateEqualOrLess") {
      const dateToCompare = moment(value);

      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : `Debe ser igual o anterior a: ${dateToCompare.format(
              "DD-MM-YYYY"
            )}`,
        (input) => {
          if (!input && !value) return true;
          const dateForm = moment(input);
          return dateForm.isValid() && dateForm.isSameOrBefore(dateToCompare);
        }
      );
    }

    if (type === "dateEqualOrMore") {
      const dateToCompare = moment(value);

      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : `Es menor a: ${dateToCompare.format("DD-MM-YYYY")}`,
        (input) => {
          if (!input || !value) return false;

          const dateForm = moment(input);

          return dateForm.isSameOrAfter(dateToCompare);
        }
      );
    }

    if (type === "dateEqualOrLessThanInput") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Fechas no son iguales",
        function (input) {
          if (!input || !value) return false;

          const dateInput = moment(this.resolve(Yup.ref(value)));
          const dateToCompare = moment(input);

          return dateInput.isSameOrBefore(dateToCompare);
        }
      );
    }

    if (type === "dateMoreThanInput") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Fechas no son iguales",
        function (input) {
          if (!input || !value) return false;

          const dateInput = moment(this.resolve(Yup.ref(value)));
          const dateToCompare = moment(input);

          return dateInput.isBefore(dateToCompare);
        }
      );
    }

    if (type === "dateEqualOrMoreThanInput") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Fechas no son iguales",
        function (input) {
          if (!input || !value) return false;

          const dateInput = moment(this.resolve(Yup.ref(value)));
          const dateToCompare = moment(input);

          return dateInput.isSameOrAfter(dateToCompare);
        }
      );
    }

    if (type === "dateLessThan") {
      const dateToCompare = moment(value);

      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : `Es menor o igual a: ${dateToCompare.format("DD-MM-YYYY")}`,
        (input) => {
          if (!input || !value) return false;

          const dateForm = moment(input);

          return (
            dateForm.isBefore(dateToCompare) || dateForm.isSame(dateToCompare)
          );
        }
      );
    }

    if (type === "dateLessThanInput") {
      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : "Fechas no son iguales",
        function (input) {
          if (!input || !value) return false;

          const dateInput = moment(this.resolve(Yup.ref(value)));
          const dateToCompare = moment(input);

          return (
            dateInput.isBefore(dateToCompare) || dateInput.isSame(dateToCompare)
          );
        }
      );
    }

    if (type === "dateMoreThan") {
      const dateToCompare = moment(value);

      schema = schema.test(
        type,
        description && description !== ""
          ? description
          : `Es mayor/igual a: ${dateToCompare.format("DD-MM-YYYY")}`,
        (input) => {
          if (!input || !value) return false;

          const dateForm = moment(input);

          return (
            dateForm.isAfter(dateToCompare) || dateForm.isSame(dateToCompare)
          );
        }
      );
    }
  }

  return schema;
};

export const validationsSchemaFile = (
  validations: IDynamicValidations[]
): Yup.MixedSchema => {
  let schema: Yup.MixedSchema = Yup.mixed().required("El campo es requerido.");

  for (const rule of validations) {
    const { type, value } = rule;

    if (type === "fileFormat" && value) {
      const allowedFormats = value.split(",").map((ext: string) => ext.trim());
      schema = schema.test(
        "fileFormat",
        `Formato inválido. Los formatos permitidos son: ${allowedFormats.join(
          ", "
        )}`,
        (input: any) => {
          if (!input) return false;
          const fileExtension = `${getExtensionFile(input.name.trim())}`;
          return allowedFormats.includes(fileExtension);
        }
      );
    }

    if (type === "fileSize" && value) {
      const maxSizeMB = parseInt(value, 10);
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      schema = schema.test(
        "fileSize",
        `Peso máximo ${maxSizeMB} MB`,
        (input: any) => {
          if (!input) return false;
          return input.size <= maxSizeBytes;
        }
      );
    }
  }

  // Reglas predeterminadas
  if (!validations.some((rule) => rule.type === "fileFormat")) {
    schema = schema.test("fileFormat", "Formato inválido.", (input: any) => {
      if (!input) return false;
      const fileExtension = `.${getExtensionFile(input.name.trim())}`;
      return TYPES_FILE.includes(fileExtension);
    });
  }

  if (!validations.some((rule) => rule.type === "fileSize")) {
    schema = schema.test(
      "fileSize",
      `Peso máximo ${bytesToMB(FILE_UNIT_MB_SIZE_LIMIT)} MB`,
      (input: any) => {
        if (!input) return false;
        return input.size <= FILE_UNIT_MB_SIZE_LIMIT;
      }
    );
  }

  return schema;
};
