import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import Auth from "@aws-amplify/auth";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL_GLOBAL;

export const useAxiosFetch = <T>(initialParams: AxiosRequestConfig<T>) => {
  const [data, setData] = useState<T>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number | null>(null);

  const onTriggerFetch = async (overrideParams: AxiosRequestConfig<T> = {}): Promise<T> => {
    const auth = await Auth.currentSession();
    const headers = {
      "Content-Type": "application/json",
      accessToken: auth.getAccessToken().getJwtToken(),
    };

    try {
      setIsLoading(true);
      const response = await axios.request<T>({ headers, ...initialParams, ...overrideParams });
      setData(response.data);
      setStatus(response.status);
      return response.data;
    } catch (error) {
      setIsError(true);
      const axiosError = error as AxiosError;
      setStatus(axiosError.response?.status || 500);
      throw axiosError;
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
  };

  const resetData = () => {
    setData(undefined);
    setIsLoading(false);
    setIsError(false);
    setStatus(null);
  };

  return [onTriggerFetch, { data, isError, isLoading, status }, resetData] as const;
};
