import { useEffect, FC, useRef } from "react";
import { useModalStore } from "../../../Commons/Stores/modalsStore";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormStore } from "../../../Commons/Stores/formStore";
import BonusInfo from "../../components/BonusInfo";
import RequestForm from "../../../Commons/Form/components/RequestForm";
import ModalLoad from "../../../../commonComponents/ModalLoad";
import ModalInfoDinamic from "../../../../commonComponents/ModalnfoDinamic";
import AlertInfo from "../../../../commonComponents/AlertInfo";
import { useBonusGetQuery } from "../../hooks/getBonusesHook";
import { ResponseData } from "../../interfaces/interfaces";
import { useBonusPostMutation } from "../../hooks/postBonusesHook";
import BreadcrumRequest from "../../../Commons/Breadcrum/BreadcrumMyRequests";
import { useSectionsStore } from "../../store/storeBonuses";

const HomeOwnerShipTask: FC = () => {
  const { state, search } = useLocation();
  const parameters = state?.parameters || search.split("?")[1] || "";
  const title = state?.title || "";
  const refLoad = useRef<HTMLButtonElement>(null);
  const refLoadClose = useRef<HTMLButtonElement>(null);
  const refStatus = useRef<HTMLButtonElement>(null);
  const { dataForm, resetDataForm } = useFormStore();
  const { mutate } = useBonusPostMutation("taskHome");
  const { dataModal, setDataModal } = useModalStore();
  const { resetSections } = useSectionsStore();
  let navigate = useNavigate();

  const { data, isLoading, isError } = useBonusGetQuery("taskHome", parameters);

  useEffect(() => {
    if (dataForm) {
      refLoad?.current?.click();
      const idSolValue = parameters.split("=")[1] || "";
      dataForm.append("idSol", idSolValue);
      mutate(dataForm, {
        onSuccess: (response) => {
          if (response.valid) {
            setTimeout(() => {
              refLoadClose?.current?.click();
            }, 500);
            setTimeout(() => {
              setDataModal({
                callBack: () => {
                  refStatus.current?.click();
                  setTimeout(() => {
                    resetDataForm();
                    navigate("/inicio");
                  }, 1000);
                },
                icon: "fa-check-circle-o",
                msg: "Solicitud enviada correctamente.",
                subtext: "",
              });
              refStatus?.current?.click();
            }, 1000);
          } else {
            handleErrorPost(response);
          }
        },
        onError: (errorPost) => {
          console.log("Error en post: ", errorPost);
          handleErrorPost({
            status: 500,
            valid: false,
            statusText: "Ha ocurrido un error al enviar solicitud.",
          });
        },
      });
    }
  }, [dataForm]);

  useEffect(() => {
    return () => {
      resetSections();
      resetDataForm();
    };
  },[]);

  const handleErrorPost = (errorPost: ResponseData) => {
    setTimeout(() => {
      refLoadClose?.current?.click();
    }, 500);
    setTimeout(() => {
      setDataModal({
        icon: "fa-exclamation-triangle",
        msg: errorPost.statusText,
        callBack: () => {
          refStatus.current?.click();
        },
        subtext: "",
      });
      refStatus?.current?.click();
    }, 1000);
  };

  useEffect(() => {
    if (!parameters) {
      navigate("/inicio");
    }
    if (isError) {
      setTimeout(() => {
        setDataModal({
          icon: "fa-exclamation-triangle",
          msg: "Ha ocurrido un error al obtener la información o la tarea no existe.",
          callBack: () => {
            refStatus.current?.click();
            navigate("/inicio");
          },
          subtext: "Por favor, intente más tarde.",
        });
        refStatus?.current?.click();
      }, 1000);
    }
  }, [isError, parameters]);

  return (
    <>
      <section>
        <BreadcrumRequest title="Tarea Premio Habitacional" />
        <h1>{data?.response.title}</h1>
        {isLoading && (
          <>
            <div className="text-center">
              <span className="spinner-border text-primary spinner-border-sm" />
              <p> Cargando información... </p>
            </div>
          </>
        )}
        {isError && (
          <>
            <div className="text-center mt-5">
              <p>
                Ha ocurrido un error al obtener la información o la tarea no
                existe. Por favor, intente más tarde.
              </p>
            </div>
          </>
        )}
        {data ? (
          <>
            {!data.response.valid ? (
              <>
                <div className="text-center mt-5">
                  <p>{data.response.reqStatus}</p>
                </div>
              </>
            ) : (
              <>
                {data.response.comment ? (
                  <div className="box mb-4">
                    <h2 className="h4 tit-section">
                      {"Correccion de Formulario"}
                    </h2>
                    <AlertInfo
                      color="alert-warning"
                      text="A continuación se indica las secciones que deben ser corregidas para su nueva evaluación."
                      colorIcon="#ffbf00"
                    />
                    <div className="ms-4">
                      <p>
                        Durante el proceso de revisión de su solicitud se han
                        generado los siguientes comentarios:{" "}
                      </p>
                      <h3 className="h5">{data.response.comment}</h3>
                    </div>
                  </div>
                ) : null}

                {data.response.informationHTML && (
                  <div className="box mb-4">
                    <h2 className="h4 tit-section">{"Detalle"}</h2>
                    <article
                      dangerouslySetInnerHTML={{
                        __html: data.response.informationHTML,
                      }}
                    />
                  </div>
                )}
                {data.response.tableSection &&
                  data.response.tableSection.length > 0 && (
                    <BonusInfo tableSection={data.response.tableSection} />
                  )}
                {data.response.sections.length > 0 && (
                  <RequestForm sections={data.response.sections} />
                )}
              </>
            )}
          </>
        ) : null}
      </section>

      <ModalLoad
        refOpen={refLoad}
        refClose={refLoadClose}
        text={"Enviando formulario..."}
      />
      <ModalInfoDinamic
        refOpen={refStatus}
        icon={dataModal.icon}
        text={dataModal.msg}
        callBack={dataModal.callBack}
        subtext={dataModal.subtext}
      />
    </>
  );
};

export default HomeOwnerShipTask;
