import React, { useState, FC } from 'react';
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  addMonths,
  subMonths,
  getDay,
} from 'date-fns';
import { es } from 'date-fns/locale';
import { CalendarEvent as Event } from './Helpers/interfaces/calendar';
import { Popover } from './Popover';
import { parseISO } from 'date-fns';

const Calendar: FC<{ data: Event[] }> = ({ data }) => {
  const events = data || [];
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const today = new Date();
  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const nextMonth = () => setCurrentDate(addMonths(currentDate, 1));
  const prevMonth = () => setCurrentDate(subMonths(currentDate, 1));

  const getEventForDay = (day: Date) => {
    return events.find((event) => {
      const eventDate = parseISO(event.date);
      const compareDate = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate()
      );
      return eventDate.getTime() === compareDate.getTime();
    });
  };

  const handleDayMouseEnter = (
    event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>, 
    dayEvent: Event | undefined
  ) => {
    if (dayEvent) {
      const button = event.currentTarget;
      const rect = button.getBoundingClientRect();
      const calendarRect = button.closest('.calendar-container')?.getBoundingClientRect();
      
      if (!calendarRect) return;

      // Calcular espacio disponible
      const spaceRight = window.innerWidth - rect.right;
      const spaceLeft = rect.left;
      const spaceTop = rect.top;
      const spaceBottom = window.innerHeight - rect.bottom;

      // Posición inicial del popover
      let left = rect.left;
      let top = rect.bottom + 5; // 5px de separación

      // Ajustar horizontalmente si no hay suficiente espacio a la derecha
      if (spaceRight < 200) { // 200px es el ancho del popover
        left = Math.max(10, rect.right - 200);
      }

      // Ajustar verticalmente si no hay suficiente espacio abajo
      if (spaceBottom < 100) { // altura aproximada del popover
        top = Math.max(10, rect.top - 100 - 5);
      }

      setPopoverPosition({ top, left });
      setSelectedEvent(dayEvent);
      setAnchorEl(button);
    }
  };

  const handleDayMouseLeave = () => {
    const timeout = setTimeout(() => {
      setSelectedEvent(null);
      setAnchorEl(null);
    }, 100);
    setTimeoutId(timeout);
  };

  const getDayStyle = (day: Date, event?: Event) => {
    const dayOfWeek = getDay(day);
    const isToday = day.getDate() === today.getDate() && 
                    day.getMonth() === today.getMonth() && 
                    day.getFullYear() === today.getFullYear();
    
    let className = 'btn ';
    let style: React.CSSProperties = { 
      width: '28px', 
      height: '28px', 
      fontSize: '0.875rem',
      border: 'none',
      padding: 0,
      borderRadius: '50%'
    };

    if (!isSameMonth(day, currentDate)) {
      className += 'text-muted';
    } else if (isToday) {
      className += 'text-white bg-danger';
    } else if (event) {
      className += 'text-white bg-primary';
    } else {
      className += 'text-dark';
    }

    return { className, style };
  };

  const getEmptyDaysAtStart = () => {
    const firstDayOfMonth = getDay(monthStart);
    return Array.from({ length: firstDayOfMonth }, (_, i) => (
      <div key={`empty-${i}`} className="d-flex justify-content-center align-items-center py-1">
        <button className="btn text-muted" style={{ visibility: 'hidden' }}>
          {i}
        </button>
      </div>
    ));
  };

  return (
    <div className="calendar-container position-relative p-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <button 
          className="btn btn-link text-decoration-none p-0" 
          onClick={prevMonth}
        >
          &larr;
        </button>
        <h6 className="mb-0 text-capitalize">
          {format(currentDate, 'MMMM yyyy', { locale: es })}
        </h6>
        <button 
          className="btn btn-link text-decoration-none p-0" 
          onClick={nextMonth}
        >
          &rarr;
        </button>
      </div>

      <div className="d-grid" style={{ gridTemplateColumns: 'repeat(7, 1fr)' }}>
        {["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"].map((day) => (
          <div key={day} className="text-center fw-bold text-secondary" style={{ fontSize: '0.75rem' }}>
            {day}
          </div>
        ))}
      </div>

      <div className="d-grid" style={{ gridTemplateColumns: 'repeat(7, 1fr)' }}>
        {getEmptyDaysAtStart()}
        {daysInMonth.map((day, index) => {
          const event = getEventForDay(day);
          const { className, style } = getDayStyle(day, event);
          return (
            <div key={`day-${index}`} className="d-flex justify-content-center align-items-center py-1">
              <button
                onMouseEnter={(e) => handleDayMouseEnter(e, event)}
                onMouseLeave={handleDayMouseLeave}
                onTouchStart={(e) => handleDayMouseEnter(e, event)}
                onTouchEnd={handleDayMouseLeave}
                className={className}
                style={style}
              >
                {format(day, 'd')}
              </button>
            </div>
          );
        })}
      </div>

      <Popover
        isOpen={!!selectedEvent}
        onClose={handleDayMouseLeave}
        anchorEl={anchorEl}
        width="200px"
        height="auto"
        style={{
          position: 'fixed',
          top: `${popoverPosition.top}px`,
          left: `${popoverPosition.left}px`,
          zIndex: 1050,
        }}
      >
        {selectedEvent && (
          <div 
            className="p-2"
            onMouseEnter={() => timeoutId && clearTimeout(timeoutId)}
            onMouseLeave={handleDayMouseLeave}
          >
            <p className="mb-1 small">{selectedEvent.description}</p>
            <small className="text-muted">
              {format(new Date(selectedEvent.date), 'd \'de\' MMMM \'de\' yyyy', { locale: es })}
            </small>
          </div>
        )}
      </Popover>
    </div>
  );
} 

export default Calendar;