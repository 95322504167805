/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import AsistenciaService from '../../../services/asistencia/asistencia-service';
import moment from 'moment/moment';
import ApprovalModal from '../aprobacion-responsive/approval-modal';
import { Accordion } from 'react-bootstrap';
import Modal from "react-bootstrap/Modal";

const ApprovalsHistory = () => {
    const estadosOption = [
        { value: "", description: "Todas" },
        { value: "P", description: "Pendientes" },
        { value: "A", description: "Aprobadas" },
        { value: "R", description: "Rechazadas" },
        { value: "V", description: "Vencidas" }
    ];
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shiftDisplay, setShiftdisplay] = useState([]);
    const [first, setFirst] = useState([]);
    const [estado, setEstado] = useState(estadosOption[0].value);
    const [fetched, setFetched] = useState(false);
    const [active, setActive] = useState(false);
    const [modalInfo, setModalInfo] = useState(null);
    const [reload, setReload] = useState(false);
    const [sortList, setSortList] = useState("ASC");
    const [selectedIds, setSelectedIds] = useState([]);
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingVerifyMassiveApprovalProcess, setLoadingVerifyMassiveApprovalProcess] = useState(false);
    const [verifyExistMassiveApprovalInProcess, setVerifyExistMassiveApprovalInProcess] = useState(null);

    const initialMassApprovalModalState = { open: false, message: "", icon: "", color: "", isConfirm: false, confirmAction: null };
    const [massApprovalModal, setMassApprovalModal] = useState(initialMassApprovalModalState);

    useEffect(() => {
        request();
        getMassiveApprovalProcess();
    }, [])

    useEffect(() => {
        request();
    }, [estado])

    useEffect(() => {
        if (reload) {
            request()
        }
    }, [reload])

    useEffect(() => {
        if (fetched && !loading || reload) {
            setTable()
        }
    }, [fetched, loading, reload])

    const getMassiveApprovalProcess = async (showConfirmation, isSuccess) => {
        setLoadingVerifyMassiveApprovalProcess(true);
        const data = await AsistenciaService.getMassiveApprovalProcess();

        setVerifyExistMassiveApprovalInProcess(data)
        if (showConfirmation) {
            const modalStatus = isSuccess ? data ? "warning" : "success" : "error";
            const modalStates = modalStatesByAction(isSuccess);
            setMassApprovalModal({
                open: true,
                message: modalStates[modalStatus].message,
                icon: modalStates[modalStatus].icon,
                color: modalStates[modalStatus].color
            });
        }
        setLoadingVerifyMassiveApprovalProcess(false);
    }

    const request = async () => {
        setFetched(false)
        await AsistenciaService.getEmployeeSchedules(setFirst, setLoading, setError, setFetched, estado);
    }
    const setTable = () => {
        const table = [];
        first.forEach(element => {
            var shifts = element?.shifts
            shifts.forEach(event => {
                var shift = {
                    id: element?.employeeId,
                    name: element?.employeeName,
                    type: event?.type,
                    schedule: event?.shiftChange?.newStartTime + " - " + event?.shiftChange?.newEndTime,
                    dayOffDate: event?.shiftChange?.dayOffDate,
                    workingDate: event?.shiftChange?.workingDate,
                    estado: event?.shiftChange?.status,
                    createdAt: event?.shiftChange?.createdAt,
                    shiftId: event?.shiftChange?.id
                }
                table.push(shift)
            })
        });
        setShiftdisplay(table.sort((a, b) => moment(b.workingDate) - moment(a.workingDate)))
    }
    const order = () => {
        if (sortList === "ASC") {
            let sorted = shiftDisplay.sort((a, b) => moment(a.workingDate, "YYYY-MM-DD").unix() - moment(b.workingDate, "YYYY-MM-DD").unix())
            setShiftdisplay(sorted)
            setSortList("DESC")
        } else {
            let sorted = shiftDisplay.sort((a, b) => moment(b.workingDate, "YYYY-MM-DD").unix() - moment(a.workingDate, "YYYY-MM-DD").unix())
            setShiftdisplay(sorted);
            setSortList("ASC")
        }
    }

    const handleFilter = (e) => {
        setEstado(e.target.value)
    }
    const filteredShift = (id, shiftIndex) => {
        const employee = first.filter(x => x.employeeId == id);
        let match = employee[0].shifts?.filter(s => s.shiftChange?.id == shiftIndex);
        let data = match[0];
        setModalInfo(data.shiftChange)
        setActive(true)
    }

    const addOrRemoveApproved = (id) => {
        const localSelectedIds = [...selectedIds];
        const verifyInclude = localSelectedIds.includes(id)
        const newSelectedIds = verifyInclude ? localSelectedIds.filter(selectedId => selectedId !== id) : [...localSelectedIds, id];

        setSelectedIds(newSelectedIds);
    }

    const showConfirmationModal = (isApproval) => {
        setMassApprovalModal({
            open: true,
            message: `¿Está seguro que desea ${isApproval ? "aprobar" : "rechazar"} las solicitudes seleccionadas?`,
            icon: "fa-question-circle-o",
            color: "yellow",
            isConfirm: true,
            confirmAction: () => onMassiveActionSelected(isApproval)
        });
    }

    const onMassiveActionSelected = async (isApproval) => {
        setLoadingSave(true);
        hideModal();
        const massApprovalResp = isApproval ? 
            await AsistenciaService.massiveApproval(selectedIds) 
            : await AsistenciaService.massiveReject(selectedIds);

        setTimeout(async () => {
            await getMassiveApprovalProcess(true, massApprovalResp !== null);
            setSelectedIds([]);
            setLoadingSave(false);
            request()
        }, 3000)
    }

    const disabledMassiveApproval = selectedIds.length === 0 || verifyExistMassiveApprovalInProcess || loadingVerifyMassiveApprovalProcess

    const hideModal = () => {
        setMassApprovalModal(initialMassApprovalModalState);
    };

    const modalStatesByAction = (isApproval) => {
        return {
        success: {
            icon: "fa-check-circle-o",
            color: "green",
            message: `${isApproval ? "Aprobacion masiva completada" : "Rechazo masivo completado"}  con exito`
        },
        error: {
            icon: "fa-times-circle-o",
            color: "red",
            message: `Hubo un error al intentar ${isApproval ? "aprobar" : "rechazar"} las solicitudes seleccionadas.`
        },
        warning: {
            icon: "fa-exclamation-circle-o",
            color: "yellow",
            message: "Esta acción puede demorar. Por favor aguarde unos segundos y vuelva a ingresar a la página."
        }}
    }

    return (
        <React.Fragment>
            <Modal show={massApprovalModal.open} onHide={hideModal} centered>
                <Modal.Body className="text-center py-4">
                    <i
                        className={`fa ${massApprovalModal.icon} fa-3x mb-2`}
                        style={{ color: massApprovalModal.color }}
                        aria-hidden="true"
                    ></i>
                    <h2 className="h5">{massApprovalModal.message}</h2>
                </Modal.Body>
                {massApprovalModal.isConfirm && (
                    <div className='d-flex align-items-center justify-content-center mt-2 mb-2'>
                    <button onClick={() => hideModal()} className="button reject">Cancelar {<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />}</button>
                    <button onClick={() => massApprovalModal.confirmAction()} className="button save">Confirmar {<i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true" />}</button>
                </div>
                )}
            </Modal>
            {!loading ? <React.Fragment>
                <section>
                    <div className='container-header'>
                        <h3>Filtrar  <div className='title-underlined'></div></h3>
                        <div className='filters-fields'>
                            <label className='label fw-bold'>Estado</label>
                            <select className="form-select filter-input" value={estado} onChange={handleFilter}>
                                {estadosOption.map((op, i) => {
                                    return <option key={i} value={op?.value}>{op?.description}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container-header">
                        <div className='details-header'>
                            <h3>Detalle  <div className='title-underlined'></div></h3>
                            <div className='button-with-tooltip'>
                                <button type="button" className="button go-back-width" disabled={disabledMassiveApproval} onClick={() => showConfirmationModal(false)}>
                                    Rechazar {loadingSave || loadingVerifyMassiveApprovalProcess ?
                                        <div className="spinner-border spinner-border-sm"></div>
                                        : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
                                </button>
                                <button type="button" className="button save" disabled={disabledMassiveApproval} onClick={() => showConfirmationModal(true)}>
                                    Aprobar {loadingSave || loadingVerifyMassiveApprovalProcess ?
                                        <div className="spinner-border spinner-border-sm"></div>
                                        : <i className="fa fa-chevron-right fa-fw fa-xs" aria-hidden="true"></i>}
                                </button>
                                {verifyExistMassiveApprovalInProcess !== null && (
                                    <span className="tooltip-text">Aprobación masiva en curso</span>
                                )}
                            </div>
                        </div>
                        <div className="table-responsive desktop-table-vacaciones-historico">
                            <table className="table table-even table-section table-borderless d-md-table">
                                <thead>
                                    <tr>
                                        <th className="cap"></th>
                                        <th className="cap">Empleado</th>
                                        <th className="cap">Tipo</th>
                                        <th className="cap">Horario</th>
                                        <th className="cap">Día a trabajar
                                            <span onClick={() => order()}> {sortList === "ASC" ? <i className="fa fa-long-arrow-down fa-fw color-orange" aria-hidden="true"></i>
                                                : <i className="fa fa-long-arrow-up fa-fw color-orange" aria-hidden="true"></i>}</span></th>
                                        <th className="cap">Dia Franco</th>
                                        <th className="cap">Fecha Solicitud</th>
                                        <th className="cap">Estado</th>
                                        <th className="cap">Detalle</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {shiftDisplay.length === 0 ? <tr><td colSpan={9} className='text-center'>No se encontraron solicitudes</td></tr> :
                                        shiftDisplay && shiftDisplay.map((event, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="">
                                                        <input
                                                            className="form-check-input border-1"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="datos-visibles"
                                                            disabled={event?.estado !== 'P'}
                                                            onChange={() => addOrRemoveApproved(event.shiftId)}
                                                            checked={selectedIds.includes(event?.shiftId)}
                                                        />
                                                    </td>
                                                    <td className="">{event?.name}</td>
                                                    <td className="">{event?.type == "HorarioPendiente" ? "Turno" : event?.type == "FrancoPendiente" ? "Franco" : event?.type}</td>
                                                    <td className="">{event?.schedule}</td>
                                                    <td className="">{moment(new Date(event?.workingDate)).format("DD/MM/YYYY")}</td>
                                                    <td className="">{event?.type == "Franco" ? moment(new Date(event?.dayOffDate)).format("DD/MM/YYYY") : " - "}</td>
                                                    <td className="">{moment(new Date(event?.createdAt)).format("DD/MM/YYYY")}</td>
                                                    <td className={`boton-estado ${event?.estado == 'A' ? "Aprobada" : event?.estado == 'P' ? "Pendiente" : event?.estado === 'R' ? "Rechazada" : "Vencida"}`}>{event?.estado == "R" ? "rechazada" : event?.estado == "A" ? "aprobada" : event?.estado === 'P' ? "pendiente" : "vencida"}</td>
                                                    <td className=""> <td className="detail-btn" onClick={() => filteredShift(event?.id, event?.shiftId)}><i className="fa fa-eye fa-fw fa-lg color-orange" aria-hidden="true"></i></td></td>
                                                </tr>
                                            )

                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-md-none">
                            {shiftDisplay.length === 0 ? <span className='text-center'>No se encontraron solicitudes</span> :
                                <Accordion className="accordion accordion-flush accordion-light accordion-table d-md-none" defaultActiveKey="0">
                                    {shiftDisplay && shiftDisplay.map((shift) => {
                                        return (
                                            <Accordion.Item key={shift?.shiftId} eventKey={shift?.shiftId}>
                                                <Accordion.Header className="accordion-header" id="accordion">
                                                    <span>Empleado: </span><strong> {shift?.name}</strong>
                                                    <span>Tipo: </span> <strong>{shift?.type === "HorarioPendiente" ? "Cambio Turno" : shift?.type === "FrancoPendiente" ? "Cambio Franco" : shift?.type === "Franco" ? "Cambio Franco" : "Cambio Turno"}</strong>
                                                </Accordion.Header>
                                                <Accordion.Body className="accordion-body p-0">
                                                    <div className="data-list-box data-list-box-even">
                                                        <ul className="data-list-section--item list-unstyled">
                                                            <li>
                                                                <span className="data-list-box__title">Horario</span>
                                                                <span className="data-list-box__text">{shift?.schedule}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Día a trabajar</span>
                                                                <span className="data-list-box__text">{moment(new Date(shift?.workingDate)).format("DD/MM/YYYY")}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Dia Franco</span>
                                                                <span className="data-list-box__text">{shift?.type == "Franco" || "FrancoPendiente" ? moment(new Date(shift?.dayOffDate)).format("DD/MM/YYYY") : "-"}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Fecha Solicitud</span>
                                                                <span className="data-list-box__text">{moment(new Date(shift?.createdAt)).format("DD/MM/YYYY")}</span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Estado</span>
                                                                <span className="data-list-box__text">
                                                                    <span className={`boton-estado ${shift?.estado == 'A' ? "Aprobada" : shift?.estado == 'P' ? "Pendiente" : shift?.estado === 'R' ? "Rechazada" : "Vencida"}`}>{shift?.estado == "R" ? "rechazada" : shift?.estado == "A" ? "aprobada" : shift?.estado === 'P' ? "pendiente" : "vencida"}</span>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="data-list-box__title">Detalle</span>
                                                                <span className="data-list-box__text detail-btn"
                                                                    onClick={() => filteredShift(shift?.id, shift?.shiftId)}><i className="fa fa-eye fa-fw fa-lg color-orange" aria-hidden="true"></i>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })}
                                </Accordion>}
                        </div>

                    </div>


                </section> </React.Fragment> : <div className="full-spinner mb-4 mt-5"><div className="spinner-border text-primary"></div></div>}
            {modalInfo && active ? <ApprovalModal active={active} setActive={setActive} data={modalInfo} setData={setModalInfo} setReload={setReload} /> : null}
        </React.Fragment>

    )
}

export default ApprovalsHistory