import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaRegCheckCircle } from "react-icons/fa";
import { TbEyeOff, TbEyeCheck } from "react-icons/tb";
import { useLunchScheduleProvider } from "../context/InsuranceContext";
import { useCompulsoryLifeInsuranceFetch } from "../hooks/useCompulsoryLifeInsuranceFetch";
import { SpinerFullScreen } from "../../../components/commonComponents/SpinerFullScreen";

export const StepThree = () => {
  const { onDownload, isLoading, handleSignDocumentRequest } = useLunchScheduleProvider();
  const { dataBeneficiaries } = useCompulsoryLifeInsuranceFetch();
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pin, setPin] = useState("");
  
  const data = dataBeneficiaries.map((item) => ({
    name: `${item.name} ${item.lastname}`,
    percentage: `${item.percentage}%`,
  }));

  const handleConformeClick = () => {
    handleSignDocumentRequest({signaturePin: pin});
    setShowModal(false);
  };

  return (
    <>
      <div className="box" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <h3>Beneficiarios</h3>
        {isLoading ? (
          <SpinerFullScreen />
        ) : (
          data.map((item, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-between", padding: "5px 0", fontSize: "14px" }}>
              <span style={{ fontWeight: "normal" }}>{item.name}</span>
              <span style={{ color: "#007bff", fontWeight: "bold" }}>{item.percentage}</span>
            </div>
          ))
        )}
      </div>
      <div className="d-flex justify-content-center" style={{ marginTop: "16px" }}>
        <button onClick={() => onDownload()} className="btn btn-primary" type="button">
          Descargar PDF
        </button>
      </div>
      <div className="d-flex justify-content-end">
        <button onClick={() => setShowModal(true)} className="btn btn-degradado" type="button">
          Firmar
        </button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
        </Modal.Header>
        <div className="sign-modal-container">
          <div className="items-wrap">
            <div className="item-container">
              <div><FaRegCheckCircle className="color-orange check-sign-icon" /></div>
              <h4 className="modal-title-sign">Firma Conforme</h4>
              <div className="sign-label">Por favor, ingrese su <div className="bold-parag">Pin de Firma</div> :</div>
            </div>
            <input maxLength={20} value={pin} onChange={(e) => setPin(e.target.value)} type={visible ? "text" : "password"} className="pin-input" />
            <span onClick={() => setVisible(vis => !vis)}>{visible ? <TbEyeCheck className="pass-icon password-on" /> : <TbEyeOff className="pass-icon password-off" />}</span>
          </div>
          <p className="mt-3 pin-recovery">
            En caso de olvidar su pin de firma puede recordarlo utilizando su palabra clave o resetearlo ingresando <a href="https://cencosud.tulegajo.com/" target="_blank" rel="noreferrer" className="pin-recovery-link">aquí</a> y luego en la opción Mi Firma &gt; Olvide mi PIN
          </p>
        </div>
        <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={handleConformeClick} className="button save">
            Conforme
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
