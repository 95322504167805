import { BreadcrumbList } from "../../components/breadcrumb";
import { Tab, Tabs } from "../../components/tab";
import { CompulsoryLifeInsurance } from "./compulsoryLifeInsurance/CompulsoryLifeInsurance";
import { OptionalLifeInsurance } from "./optionalLifeInsurance/OptionalLifeInsurance";
import { BurialInsurance } from "./burialInsurance/BurialInsurance";

export const InsuranceContent = () => {
  return (
    <div className="p-sm-3 container m-auto">
      <BreadcrumbList items={["Seguros"]} />
      <h1>Seguros</h1>

      <div className="mb-4">
        <p><strong>Previo a la actualización de los datos de las pólizas verifica la siguiente información:</strong></p>
        <div className="border border-primary p-3 rounded-4 mb-3">
          <div className="box-scroll" style={{ maxHeight: 100 + "px" }}>
            <ul>
              <li>
                Verifica tus datos personales vigentes en la sección de “Mi Información” y en el caso de no ser correctos por favor modifícalos antes de continuar al siguiente paso.

              </li>
              <li>
                Verifica que la dirección de correo personal contenida en la sección de “Mi información” sea la correcta ya que utilizaremos ese correo para las notificaciones del portal.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Tabs headerClassName="gap-5 mb-4">
        <Tab label="Seguro de vida obligatorio" icon="plus" variant="line">
          <CompulsoryLifeInsurance />
        </Tab>
        <Tab
          label="Seguro de vida Optativo"
          icon="edit"
          variant="line"
          disabled
        >
          <OptionalLifeInsurance />
        </Tab>
        <Tab
          label="Seguro Sepelios"
          icon="hand"
          variant="line"
          disabled
        >
          <BurialInsurance />
        </Tab>
      </Tabs>
    </div>
  );
};
