import { FC } from "react";
import { Controller } from "react-hook-form";
import { InputForm } from "../../RemoteJob/interfaces/interfaces";

const CheckboxInput: FC<InputForm> = ({ formState, input, control }) => {
  return (
    <>
      <span className="data-list-box__text ms-2">
        <Controller
          name={input.key}
          control={control}
          defaultValue={input.defaultValue || ""}
          render={({ field }) => (
            <label className="form-check-label">
              <input
                type="checkbox"
                id={input.key}
                checked={!!field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                className="form-check-input"
                disabled={input.readOnly}
              />
              {input.placeholder}
            </label>
          )}
        />
        {formState.errors[input.key] && formState.errors[input.key]?.message ? (
          <div className="invalid-feedback d-block">
            {formState.errors[input.key]?.message}
          </div>
        ) : null}
      </span>
    </>
  );
};

export default CheckboxInput;
