
import { urlsAws } from "../../../resources/foo/api-endpoints";
import { fetchData } from "../helpers/utilities";

const getDirectAccessIcons = async () => {
    return [
        {
            "icon": "fa-usd",
            "title": "Liquidaciones",
            "url": "/liquidacion"
        },
        {
            "icon": "fa-suitcase",
            "title": "Vacaciones",
            "url": "/solicitar-vacaciones"
        },
        {
            "icon": "fa-pencil-square-o",
            "title": "Firma Documentos",
            "url": "/mis-documentos"
        },
        {
            "icon": "fa-address-card-o",
            "title": "Mis Solicitudes",
            "url": "/mis-solicitudes"
        }
    ];
};

const getNewsBanner = async ({ isMobile = false }) => {
    if (!isMobile) {
        const isMobileDevice = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        isMobile = isMobileDevice;
    } 

    const url = `${urlsAws.getFeaturedNews}?banner=${isMobile.toString()}`;
    return await fetchData({ url });
};

const getMyTeam = async () => {
    const url = urlsAws.getMiEquipoInicio;
    return await fetchData({ url });
};

const getNextBirthdays = async () => {
    const url = urlsAws.getProximosCumpleanos;
    return await fetchData({ url });
};

const getHolidays = async () => {
    const url = urlsAws.getFeriados;
    return await fetchData({ url, saveCache: true });
};

export { getDirectAccessIcons, getNewsBanner, getMyTeam, getNextBirthdays, getHolidays };
